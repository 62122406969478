import * as React from 'react';
import {
    Box, Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Typography,
    Paper, Container, Grid2, IconButton,
    Button
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useDispatch, useSelector } from 'react-redux';
import { getscdhs } from '../../features/Clients/analyzation/analyzationSlice'
import { getAllCountriesName } from '../../features/Clients/countries/countriesSlice'
import Loading from '../../BuiltInComponent/Loading'
import Navbar from '../../compnents/Setup/Navbar'
import '../../services/globalVariables'
import AutoComplete from '../../BuiltInComponent/AutoComplete1'

const forRowTop = { fontWeight: 'bolder', fontSize: 18, background: 'grey' }
const forSubRowTop = { fontWeight: 'bolder', fontSize: 12 }

function HS6(props) {
    const { row } = props;

    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="center">{row.HS4ID}</TableCell>
                <TableCell align="left">{row.HS4}</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bolder', fontSize: 12 }}>$ {row.HS4Total}</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bolder', fontSize: 12 }}>$ {row.HS42020}</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bolder', fontSize: 12 }}>$ {row.HS42021}</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bolder', fontSize: 12 }}>$ {row.HS42022}</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bolder', fontSize: 12 }}>$ {row.HS42023}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                HS 6
                            </Typography>
                            <Table size="small" aria-label="purchases" sx={{ minWidth: 850 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={forRowTop}>HS 6 ID</TableCell>
                                        <TableCell align="left" sx={forRowTop}>HS 6 Desc</TableCell>
                                        <TableCell align="right" sx={{ ...forRowTop, textAlign: 'right' }}>
                                            Trade Value
                                        </TableCell>
                                        <TableCell align="right" sx={{ ...forRowTop, textAlign: 'right' }}>
                                            2020
                                        </TableCell>
                                        <TableCell align="right" sx={{ ...forRowTop, textAlign: 'right' }}>
                                            2021
                                        </TableCell>
                                        <TableCell align="right" sx={{ ...forRowTop, textAlign: 'right' }}>
                                            2022
                                        </TableCell>
                                        <TableCell align="right" sx={{ ...forRowTop, textAlign: 'right' }}>
                                            2023
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.ArrHS6.map((historyRow) => (
                                        <TableRow key={historyRow.HS6ID}>
                                            <TableCell component="th" scope="row" align='center'>
                                                {historyRow.HS6ID}
                                            </TableCell>
                                            <TableCell align='left'>{historyRow.HS6}</TableCell>
                                            <TableCell align='right'>$ {historyRow.HS6Total}</TableCell>
                                            <TableCell align='right'>$ {historyRow.HS62020}</TableCell>
                                            <TableCell align='right'>$ {historyRow.HS62021}</TableCell>
                                            <TableCell align='right'>$ {historyRow.HS62022}</TableCell>
                                            <TableCell align='right'>$ {historyRow.HS62023}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


function HS4(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="center" sx={forSubRowTop}>{row.HS2ID}</TableCell>
                <TableCell align="left" sx={forSubRowTop}>{row.HS2}</TableCell>
                <TableCell align="right" sx={{ ...forSubRowTop, textAlign: 'right' }}>$ {row.HS2Total}</TableCell>
                <TableCell align="right" sx={{ ...forSubRowTop, textAlign: 'right' }}>$ {row.HS22020}</TableCell>
                <TableCell align="right" sx={{ ...forSubRowTop, textAlign: 'right' }}>$ {row.HS22021}</TableCell>
                <TableCell align="right" sx={{ ...forSubRowTop, textAlign: 'right' }}>$ {row.HS22022}</TableCell>
                <TableCell align="right" sx={{ ...forSubRowTop, textAlign: 'right' }}>$ {row.HS22023}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                HS 4
                            </Typography>
                            <Table size="small" sx={{ minWidth: 750 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={forRowTop} />
                                        <TableCell sx={forRowTop} />
                                        <TableCell align="center" sx={forRowTop}>HS 4 ID</TableCell>
                                        <TableCell align="left" sx={forRowTop}>HS 4 Desc</TableCell>
                                        <TableCell align="right" sx={{ ...forRowTop, textAlign: 'right' }}>HS 4 Total</TableCell>
                                        <TableCell align="right" sx={{ ...forRowTop, textAlign: 'right' }}>2020</TableCell>
                                        <TableCell align="right" sx={{ ...forRowTop, textAlign: 'right' }}>2021</TableCell>
                                        <TableCell align="right" sx={{ ...forRowTop, textAlign: 'right' }}>2022</TableCell>
                                        <TableCell align="right" sx={{ ...forRowTop, textAlign: 'right' }}>2023</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row && row.ArrHS4.map((row) => (
                                        <HS6 key={row.name} row={row} />
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function HS2() {

    const dispatch = useDispatch()

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const { scdhs, ishsLoading } = useSelector(state => state.analyzation)
    const { allCountries } = useSelector(state => state.countries)
    const [arrayForCN1, setArrayForCN1] = React.useState([])
    const [arrayForCN2, setArrayForCN2] = React.useState([])

    React.useEffect(() => {
        dispatch(getAllCountriesName())
    }, [dispatch])

    // Handle pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const forACOC_C1 = (gotArray) => {
        setArrayForCN1(gotArray)
    }

    const forACOC_C2 = (gotArray) => {
        setArrayForCN2(gotArray)
    }

    const onSubmit = async () => {
        dispatch(getscdhs({ from: arrayForCN1, to: arrayForCN2 }))
    }

    return (
        <>
            <Navbar />
            <Container >
                <Grid2 container spacing={2}>
                    <Grid2 size={5}>
                        <AutoComplete listData={allCountries && allCountries} onChangeForGetArray={forACOC_C1} maxLimit={3} />
                    </Grid2>
                    <Grid2 size={5}>
                        <AutoComplete listData={allCountries && allCountries} onChangeForGetArray={forACOC_C2} />
                    </Grid2>
                    <Grid2 size={2}>
                        <Button
                            variant='contained'
                            onClick={onSubmit} sx={{ mt: 1 }}
                            fullWidth
                        >Refresh Data
                        </Button>
                    </Grid2>
                </Grid2>
                {ishsLoading ? <Loading /> :
                    <>
                        <TableContainer component={Paper}>
                            <Typography sx={{ m: 1 }} variant="h6" gutterBottom component="div">
                                HS 2
                            </Typography>
                            <Table aria-label="collapsible table" fullWidth>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={forRowTop} />
                                        <TableCell sx={forRowTop} />
                                        <TableCell align="center" sx={forRowTop}>HS 2 ID</TableCell>
                                        <TableCell align="left" sx={forRowTop}>HS 2 Desc</TableCell>
                                        <TableCell align="right" sx={{ ...forRowTop, textAlign: 'right' }}>HS 2 Total</TableCell>
                                        <TableCell align="right" sx={{ ...forRowTop, textAlign: 'right' }}>2020</TableCell>
                                        <TableCell align="right" sx={{ ...forRowTop, textAlign: 'right' }}>2021</TableCell>
                                        <TableCell align="right" sx={{ ...forRowTop, textAlign: 'right' }}>2022</TableCell>
                                        <TableCell align="right" sx={{ ...forRowTop, textAlign: 'right' }}>2023</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {scdhs && scdhs
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => (
                                            <HS4 key={row.name} row={row} />
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer >
                        <TablePagination
                            rowsPerPageOptions={[50, 100, 200]}
                            component="div"
                            count={scdhs && scdhs.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        // sx={{ maxWidth: 450 }}
                        />
                    </>}
            </Container >
        </>
    );
}
