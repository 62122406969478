import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Toolbar, Button, IconButton, Drawer, Typography, Divider } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../features/Clients/authorization/authSlice';
import { useNavigate } from 'react-router-dom';
import DBMenu from '../Setup/NavbarDynamic'
const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

export default function Navbar() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(state => state.auth)

    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {

    }, [])
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static"
                sx={{ background: 'linear-gradient(90deg, rgba(16,191,248,1) 0%, rgba(12,150,180,1) 100%)' }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        onClick={handleDrawerOpen}
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div" sx={{ flexGrow: 1, color: 'white', fontWeight: 'bolder', cursor: "pointer" }}
                        onClick={() => navigate('/')}
                    >
                        COMPANY LOGO
                    </Typography>
                    <Typography
                        variant="h6"
                        component="div" sx={{ flexGrow: 1, color: 'white', fontWeight: 'bolder', cursor: "pointer" }}
                        onClick={() => navigate('/Analyzation')}
                    >
                        Analyzation
                    </Typography>
                    {user ? <Button variant="h6" color="inherit" onClick={() => dispatch(logOut())} sx={{ fontWeight: 'bolder', fontSize: 18, color: 'white' }}>Log Out</Button> :
                        <Button color="inherit" onClick={() => navigate('/SignIn')} >Login</Button>}

                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                {user ? <DBMenu /> : <></>}
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
            </Main>
        </Box >
    );
}
