import * as React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

export default function BasicMenu() {

    // const dispatch = useDispatch()
    const navigate = useNavigate()

    // const { user } = useSelector((state) => state.auth)

    // const { parentMenu } = useSelector((state) => state.menu)

    // React.useEffect(() => {
    //     if (!localStorage.getItem('olduser')) {
    //         localStorage.setItem('olduser', JSON.stringify(user))
    //         if (user) {
    //             if (!parentMenu.length > 0) {
    //                 // dispatch(getParentMenu(user.User_Id))
    //             }
    //         }
    //     } else {
    //         if (JSON.parse(localStorage.getItem('user')).User_Id !== JSON.parse(localStorage.getItem('olduser')).User_Id) {
    //             localStorage.removeItem('olduser')
    //             localStorage.setItem('olduser', JSON.stringify(user))
    //             // dispatch(getParentMenu(user[0].ID))
    //         } else {
    //             if (user) {
    //                 if (!parentMenu.length > 0) {
    //                     // dispatch(getParentMenu(user.User_Id))
    //                 }
    //             }
    //         }
    //     }
    // }, [dispatch, user, parentMenu.length])

    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Analyzation</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <nav aria-label="main mailbox folders">
                            <List>
                                <ListItem sx={{ p: 1 }}>
                                    <ListItemButton onClick={() => navigate('/Analyzation')}>
                                        <ListItemText primary={'Analyzation'} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                            </List>
                        </nav>
                    </Box>
                </AccordionDetails>
            </Accordion>

        </>
    );
}