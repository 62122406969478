import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getscd, getscdhs } from '../../features/Clients/analyzation/analyzationSlice'
import Navbar from '../Setup/Navbar';
import { Grid2, Typography } from '@mui/material';
import EnhancedTable from '../../BuiltInComponent/TableWithPagination';
import Loading from '../../BuiltInComponent/Loading'
import { useTheme } from '@emotion/react';
import '../../services/globalVariables'
const columns = [
    { fieldName: 'SectionID', cName: 'Section ID', width: 90, textAlign: 'left', dType: 'string' },
    { fieldName: 'Section', cName: 'Section', width: 50, textAlign: 'left', dType: 'string' },
    { fieldName: 'HS2ID', cName: 'HS 2 ID', width: 50, textAlign: 'center', dType: 'string' },
    { fieldName: 'HS2', cName: 'HS 2 Desc', width: 50, textAlign: 'left', dType: 'string' },
    { fieldName: 'HS4ID', cName: 'HS 4 ID', width: 50, textAlign: 'center', dType: 'string' },
    { fieldName: 'HS4', cName: 'HS 4 Desc', width: 50, textAlign: 'left', dType: 'string' },
    { fieldName: 'HS6ID', cName: 'HS 6 ID', width: 50, textAlign: 'center', dType: 'string' },
    { fieldName: 'HS6', cName: 'HS 6', width: 50, textAlign: 'left', dType: 'string' },
    { fieldName: 'TradeValue', cName: 'Trade Value', width: 50, textAlign: 'right', dType: 'number' },
    { fieldName: 'PurchasingCountry', cName: 'Buyer Country', width: 50, textAlign: 'left', dType: 'string' },
    { fieldName: 'Edit', cName: 'Edit', width: 50, textAlign: 'left', dType: 'string' },
    { fieldName: 'Delete', cName: 'Delete', width: 50, textAlign: 'left', dType: 'string' },
];

export default function DataGridDemo() {

    const theme = useTheme()

    const dispatch = useDispatch()
    const { scd, isLoading } = useSelector(state => state.analyzation)

    React.useEffect(() => {
        console.log(global.countryCode, global.targetYear)
        const scdData = { countryShortCode: global.countryCode, targetYear: global.targetYear }
        dispatch(getscd(scdData))
        dispatch(getscdhs(scdData))
    }, [dispatch])

    const onEditClick = (id) => {
        console.log(id)
    }

    const onDeleteClick = (id) => {
        console.log('Clicked on Delete', id)
    }
    return (
        <>
            <Navbar />
            <Grid2 container spacing={1}>
                <Grid2 size={4}></Grid2>
                <Grid2 size={4}>
                    <Typography sx={{
                        padding: 1, background: theme.palette.primary.main, color: 'white', borderRadius: 5,
                        fontSize: 30, fontWeight: 'boler', textAlign: 'center', textShadow: 'black 2px 2px 5px',
                        marginLeft: 6
                    }}>{global.countryName}</Typography>
                </Grid2>
                <Grid2 size={4}></Grid2>
            </Grid2>
            {/* <TableData tableColumns={columns} tableData={scd && scd} /> */}
            {isLoading ?
                <Loading loadingText={'Country Data'} /> :
                <EnhancedTable
                    perPage={50} tableColumns={columns} tableData={scd && scd}
                    onEditClick={onEditClick} onDeleteClick={onDeleteClick} />}
        </>
    );
}
