import { Container, Typography } from '@mui/material'
import React from 'react'

export default function ErrorMsg(props) {

    const msg = props.message
    const msgColor = props.msgColor
    return (
        <Container>
            <Typography
                sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: `${msgColor}`,
                    animation: 'blinkingText 1.5s infinite',  // Apply the blink animation
                    '@keyframes blinkingText': {
                        '0%': { opacity: 1 },
                        '50%': { opacity: 0 },
                        '100%': { opacity: 1 },
                    },
                    textAlign: 'center'
                }}

            // style={{ textAlign: 'center', fontSize: 20, color: 'red', fontWeight: 'bolder' }}
            >{msg}</Typography>
        </Container>
    )
}