import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashbaord from "./compnents/Setup/Dashbaord";
import CountryWiseSummery from './compnents/CleintComponent/CountryWiseSummery'
import SingleCountryData from './compnents/CleintComponent/SingleCountryData'
import SignIn from "./compnents/CleintComponent/SignIn";
import CollapsData from './compnents/CleintComponent/Analyzation';
const theme = createTheme({
  palette: {
    primary: {
      main: '#3f9cff'
    },
    secondary: {
      main: '#ffffff',
    },
    custom: {
      main: "#ffffff"
    }
  },
  typography: {
    fontFamily: [
      'Calibri'
    ].join(','),
  },
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashbaord />} />
          <Route path="/CWS" element={<CountryWiseSummery />} />
          <Route path="/SCD" element={<SingleCountryData />} />
          <Route path="/SignIn" element={<SignIn />} />
          <Route path="/Analyzation" element={<CollapsData />} />

        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
