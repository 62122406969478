import React, { useEffect } from 'react'
import { Container, Grid2, Typography } from '@mui/material'
import { keyframes } from '@mui/system';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CircleIcon from '@mui/icons-material/Circle';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from './Navbar'
import Loading from '../../BuiltInComponent/Loading'
import { getyws } from '../../features/Clients/analyzation/analyzationSlice'

function DashbaordBlock(props) {
    // const dispatch = useDispatch()
    const navigate = useNavigate()
    const year = props.year
    const Volume = props.Volume
    const taskColor = props.taskColor
    const currCondition = props.currCondition
    const typoStyle = {
        textAlign: 'center', fontSize: 20, fontWeight: 'bolder', color: 'white', textShadow: 'black 3px 3px 5px',

    }

    const moveUp = keyframes`
        0% {
            transform: translateY(30px);
        }
        50% {
            transform: translateY(0); /* Change position by 200px */
        }
        100% {
            transform: translateY(30px); /* Change position by 200px */
        }`;

    const getYearData = async (year) => {
        global.targetYear = year
        // const yearData = { targetYear: year }
        // dispatch(getcws(yearData))
        navigate('/CWS')
    }
    return (
        <>
            <Grid2 container spacing={1} sx={{
                background: 'linear-gradient(90deg, rgba(16,191,248,1) 0%, rgba(15,150,248,1) 100%)', padding: 2, borderRadius: 2,
                boxShadow: 'black 2px 2px 5px',
                cursor: 'pointer'
            }}
                onClick={() => getYearData(year)}
            >
                <Grid2 size={8} sx={{ background: `${taskColor}, 0.5)`, borderRadius: 5, }}>
                    <Typography style={{ ...typoStyle, fontSize: 30 }} > {year}</Typography>
                </Grid2>
                <Grid2 size={4} >
                    <Typography style={{ textAlign: 'right', color: `${taskColor}, 1)` }}>{currCondition === 'nor' ?
                        <CircleIcon fontSize="large" /> : currCondition === 'Inc' ?
                            <ArrowUpwardIcon fontSize="large"
                                sx={{
                                    borderRadius: 50,
                                    boxShadow: 'yellow 0px 4px 15px',
                                    // animation: 'blinkingText 1.5s infinite',
                                    // '@keyframes blinkingText': {
                                    //     '0%': { boxShadow: '#ceb600 0px 10px 25px' },
                                    //     '50%': { boxShadow: '#ceb600 0px 0px 0px' },
                                    //     '100%': { boxShadow: '#ceb600 0px 10px 25px' },
                                    // },
                                    animation: `${moveUp} 2s ease-in-out infinite alternate`,
                                }}
                            /> :
                            <ArrowDownwardIcon fontSize="large"
                                sx={{
                                    // boxShadow: 'red 0px 4px 15px',
                                    // borderRadius: 50,
                                    // animation: `${moveDown} 2s ease-in-out infinite alternate`,

                                }} />}</Typography>
                </Grid2>
                <Grid2 size={12}>
                    <Typography style={typoStyle}>$ {Volume && Volume.toLocaleString("en-US")}</Typography>
                </Grid2>
            </Grid2 >
        </>
    );
}


export default function Dashbaord() {

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { yws, isLoading } = useSelector(state => state.analyzation)

    useEffect(() => {
        if (!user) {
            navigate('SignIn')
        }
    }, [user, navigate])

    useEffect(() => {
        dispatch(getyws())
    }, [dispatch])
    return (
        <>
            <Navbar />
            <Container sx={{ mt: 10 }} >
                <Grid2 container spacing={2}>
                    <Grid2 size={12}>
                        <Typography style={{ textAlign: 'center', fontSize: 50 }}>
                            Dashbaord
                        </Typography>
                    </Grid2>
                    {isLoading ? <Loading /> : yws && yws.map((row, index) => (
                        <Grid2 size={isMobile ? 12 : 3} >
                            <DashbaordBlock year={row.year} Volume={row.Volume}
                                lastyear={index === 0 ? null : yws[index - 1].year}
                                lastVolume={index === 0 ? null : yws[index - 1].Volume}
                                taskColor={index === 0 ? null : yws[index].Volume > yws[index - 1].Volume ? 'rgba(29, 89, 0' : 'rgba(206, 0, 0'}
                                currCondition={index === 0 ? 'nor' : yws[index].Volume > yws[index - 1].Volume ? 'Inc' : 'Dec'} />
                        </Grid2>
                    ))}
                </Grid2>
            </Container >
        </>
    )
}
