import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import BasicAlerts from './Alert';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags(props) {
    const { listData, maxLimit = 3, onChangeForGetArray } = props;
    const keys = listData && Object.keys(listData[0]);
    const [selectValue, setSelectedValues] = React.useState([]);
    const [showAlert, setShowAlert] = React.useState(false);

    const handleChange = (event, newValue) => {
        if (newValue.length > maxLimit) {
            setShowAlert(true);
            return;
        }

        setShowAlert(false);
        setSelectedValues(newValue);

        const arrayForAPI = newValue.map(item => item[keys[1]]);
        onChangeForGetArray(arrayForAPI);
    };
    const getCloseCommand = (statue) => {
        setShowAlert(statue)
    }
    return (
        <div>
            {showAlert && <BasicAlerts msg={'You can only select up to 3 items.'} getCloseCommand={getCloseCommand} />}  {/* Conditional rendering of alert */}
            <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={listData || []}
                disableCloseOnSelect
                getOptionLabel={(option) => option[keys[0]]}
                value={selectValue}
                onChange={handleChange}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option[keys[0]]}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params} label="Countries Name" placeholder="Favorites" />
                )}
            />
        </div>
    );
}

