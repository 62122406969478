import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

export default function BasicAlerts(props) {
    const msg = props.msg
    return (
        <Stack sx={{ width: '100%', }} spacing={2}>
            <Alert variant="filled" severity="error" onClose={() => {
                const getCloseCommandFunc = props.getCloseCommand
                getCloseCommandFunc(false)
            }}>
                <Typography sx={{ color: 'white', fontSize: 16, fontWeight: 'bolder' }}>{msg}</Typography>
            </Alert>
        </Stack>
    );
}