import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/Clients/authorization/authSlice'
import analyzationReducer from '../features/Clients/analyzation/analyzationSlice'
import countriesReducer from '../features/Clients/countries/countriesSlice'

export const store = configureStore({
    reducer: {
        auth: authReducer,
        analyzation: analyzationReducer,
        countries: countriesReducer
    },
})