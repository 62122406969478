import React, { useEffect } from 'react'
import { Container, Grid2, Typography } from '@mui/material'
import { keyframes } from '@mui/system';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CircleIcon from '@mui/icons-material/Circle';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../Setup/Navbar'
import Loading from '../../BuiltInComponent/Loading'
import { getcws } from '../../features/Clients/analyzation/analyzationSlice'

function CWSBlock(props) {
    // const dispatch = useDispatch()
    const navigate = useNavigate()
    // const targetYear = props.targetYear
    const countryName = props.countryName
    const Volume = props.Volume
    const taskColor = props.taskColor
    const currCondition = props.currCondition
    const countryID = props.countryID
    const TradeValue = props.tradeValue
    const HSName = props.HSName
    const typoStyle = {
        textAlign: 'center', fontSize: 20, fontWeight: 'bolder', color: 'white', textShadow: 'black 3px 3px 5px',

    }
    const moveUp = keyframes`
        0% {
            transform: translateY(30px);
        }
        50% {
            transform: translateY(0); /* Change position by 200px */
        }
        100% {
            transform: translateY(30px); /* Change position by 200px */
        }`;

    const getYearData = async (countryID, countryName) => {

        global.countryCode = countryID
        global.countryName = countryName
        navigate('/SCD')

    }
    return (
        <>
            <Grid2 container spacing={1} sx={{
                background: 'linear-gradient(90deg, rgba(16,191,248,1) 0%, rgba(15,150,248,1) 100%)', padding: 2, borderRadius: 2,
                boxShadow: 'black 2px 2px 5px',
                cursor: 'pointer'
            }}
                onClick={() => getYearData(countryID, countryName)}
            >
                <Grid2 size={8} sx={{ background: `${taskColor}, 0.5)`, borderRadius: 5, }}>
                    <Typography style={{ ...typoStyle, fontSize: 30 }} > {countryName}</Typography>
                </Grid2>
                <Grid2 size={4} >
                    <Typography style={{ textAlign: 'right', color: `${taskColor}, 1)` }}>{currCondition === 'nor' ?
                        <CircleIcon fontSize="large" /> : currCondition === 'Inc' ?
                            <ArrowUpwardIcon fontSize="large"
                                sx={{
                                    borderRadius: 50,
                                    boxShadow: 'yellow 0px 4px 15px',
                                    // animation: 'blinkingText 1.5s infinite',
                                    // '@keyframes blinkingText': {
                                    //     '0%': { boxShadow: '#ceb600 0px 10px 25px' },
                                    //     '50%': { boxShadow: '#ceb600 0px 0px 0px' },
                                    //     '100%': { boxShadow: '#ceb600 0px 10px 25px' },
                                    // },
                                    animation: `${moveUp} 2s ease-in-out infinite alternate`,
                                }}
                            /> :
                            <ArrowDownwardIcon fontSize="large"
                                sx={{
                                    // boxShadow: 'red 0px 4px 15px',
                                    // borderRadius: 50,
                                    // animation: `${moveDown} 2s ease-in-out infinite alternate`,

                                }} />}</Typography>
                </Grid2>
                <Grid2 size={12}>
                    <Typography style={typoStyle}>$ {Math.round(Volume).toLocaleString("en-US")}</Typography>
                </Grid2>
                <Grid2 size={12}>
                    <Typography sx={{
                        color: 'yellow',
                        fontWeight: 'bolder',
                        textShadow: 'red 2px 2px 5px',
                    }}>{`Most Sold : '${HSName}' $ ${Math.round(TradeValue).toLocaleString("en-US")}`}</Typography>
                </Grid2>
            </Grid2 >
        </>
    );
}


export default function CountryWiseSummery() {

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { cws, isLoading } = useSelector(state => state.analyzation)

    useEffect(() => {
        if (!user) {
            navigate('SignIn')
        }
    }, [user, navigate])

    useEffect(() => {
        dispatch(getcws({ targetYear: global.targetYear }))
    }, [dispatch])

    // const [tradeValues, setTradeValues] = useState({});


    // const getTradeValue = useCallback(async (targetYear, SourceCountry) => {
    //     const countryData = { targetYear: targetYear, countryShortCode: SourceCountry };
    //     let cdData = await dispatch(getcd(countryData));  // Assuming dispatch is working
    //     const cdArray = []
    //     cdArray.push({ TradeValue: cdData.payload[0].TradeValue, HSName: cdData.payload[0].HS6ID })
    //     return cdArray;
    // }, [dispatch]);

    // const getTradeValue = async (targetYear, SourceCountry) => {
    //     const countryData = { targetYear: targetYear, countryShortCode: SourceCountry };
    //     let cdData = await dispatch(getcd(countryData));  // Assuming dispatch is working
    //     const cdArray = []
    //     cdArray.push({ TradeValue: cdData.payload[0].TradeValue, HSName: cdData.payload[0].HS6ID })
    //     return cdArray;
    // };

    // useEffect(() => {
    //     const fetchAllTradeValues = async () => {
    //         const newTradeValues = {};

    //         for (const row of cws) {
    //             const value = await getTradeValue(row.targetYear, row.SourceCountry);
    //             newTradeValues[row.SourceCountry] = value;
    //         }

    //         setTradeValues(newTradeValues);
    //     };

    //     if (cws && cws.length > 0) {
    //         fetchAllTradeValues();
    //     }
    // }, [cws, getTradeValue]);

    return (
        <>
            <Navbar />
            <Container sx={{ mt: 10 }}>
                <Grid2 container spacing={2}>
                    <Grid2 size={12}>
                        <Typography style={{ textAlign: 'center', fontSize: 50 }}>
                            Country Wise Summery
                        </Typography>
                    </Grid2>
                    {isLoading ? <Loading /> : cws && cws.map((row, index) => (
                        <Grid2 key={index} size={isMobile ? 12 : 3}>
                            <CWSBlock
                                countryName={row.counName}
                                Volume={row.currVolume}
                                lastVolume={row.lastVolume}
                                taskColor={row.currVolume > row.lastVolume ? 'rgba(29, 89, 0' : 'rgba(206, 0, 0'}
                                currCondition={row.currVolume > row.lastVolume ? 'Inc' : 'Dec'}
                                countryID={row.SourceCountry}
                                targetYear={row.targetYear}
                                tradeValue={row.tradeValue || 'Loading...'}
                                HSName={row.HS6ID}  // Display TradeValue or 'Loading'
                            />
                        </Grid2>
                    ))}
                    {/* {cws && cws.map((row, index) => (
                        < Grid2 size = { isMobile? 12: 3 } >
                        <CWSBlock countryName={row.counName} Volume={row.currVolume}
                            lastVolume={row.lastVolume}
                            taskColor={row.currVolume > row.lastVolume ? 'rgba(29, 89, 0' : 'rgba(206, 0, 0'}
                            currCondition={row.currVolume > row.lastVolume ? 'Inc' : 'Dec'}
                            countryID={row.SourceCountry} targetYear={row.targetYear}
                        />
                        </Grid2>
                    ))} */}
                </Grid2>
            </Container >
        </>
    )
}
