import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import '../../../services/globalVariables'
import axios from 'axios'

const API_URL = global.API_URL + 'analyzation/'

const initialState = {
    yws: null,
    cws: null,
    cd: null,
    scd: null,
    scdhs: null,
    isLoading: false,
    ishsLoading: false,
    isError: false,
    isSuccess: false,
    message: ""
}

export const getyws = createAsyncThunk('ana/gyws', async (_, thunkAPI) => {
    try {
        return (await axios.get(`${API_URL}getYWS`)).data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }

})

export const getcws = createAsyncThunk('ana/gcws', async (yearData, thunkAPI) => {
    try {
        return (await axios.post(`${API_URL}getCWS`, yearData)).data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }

})

export const getcd = createAsyncThunk('ana/gcd', async (countryData, thunkAPI) => {
    try {
        return (await axios.post(`${API_URL}getCD`, countryData)).data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }

})

export const getscd = createAsyncThunk('ana/gscd', async (countryData, thunkAPI) => {
    try {
        return (await axios.post(`${API_URL}getSCD`, countryData)).data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }

})

export const getscdhs = createAsyncThunk('ana/gscdhs', async (countryData, thunkAPI) => {
    try {
        return (await axios.post(`${API_URL}getSCDHS`, countryData)).data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }

})

export const analyzationSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.ishsLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
            state.yws = null
            state.cws = null
            state.cd = null
            state.scd = null
            state.scdhs = null
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getyws.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getyws.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.yws = action.payload
            })
            .addCase(getyws.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.yws = null
            })
            .addCase(getcws.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getcws.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.cws = action.payload
            })
            .addCase(getcws.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.cws = null
            })
            .addCase(getcd.pending, (state) => {
                state.ishsLoading = true
            })
            .addCase(getcd.fulfilled, (state, action) => {
                state.ishsLoading = false
                state.isSuccess = true
                state.cd = action.payload
            })
            .addCase(getcd.rejected, (state, action) => {
                state.ishsLoading = false
                state.isError = true
                state.message = action.payload
                state.cd = null
            })
            .addCase(getscd.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getscd.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.scd = action.payload
            })
            .addCase(getscd.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.scd = null
            })
            .addCase(getscdhs.pending, (state) => {
                state.ishsLoading = true
            })
            .addCase(getscdhs.fulfilled, (state, action) => {
                state.ishsLoading = false
                state.isSuccess = true
                state.scdhs = action.payload
            })
            .addCase(getscdhs.rejected, (state, action) => {
                state.ishsLoading = false
                state.isError = true
                state.message = action.payload
                state.scdhs = null
            })
    }
})

export const { reset } = analyzationSlice.actions

export default analyzationSlice.reducer
