import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import '../../../services/globalVariables'
import axios from 'axios'

const API_URL = global.API_URL + 'countries/'

const initialState = {
    allCountries: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: ""
}

export const getAllCountriesName = createAsyncThunk('ana/gyws', async (_, thunkAPI) => {
    try {
        return (await axios.get(`${API_URL}getAllCountriesName`)).data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }

})

export const countriesSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
            state.yws = null
            state.cws = null
            state.cd = null
            state.scd = null
            state.scdhs = null
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllCountriesName.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAllCountriesName.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.allCountries = action.payload
            })
            .addCase(getAllCountriesName.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.allCountries = null
            })
    }
})

export const { reset } = countriesSlice.actions

export default countriesSlice.reducer
